




























































import {
  defineComponent,
  computed,
  PropType,
  useContext
} from '@nuxtjs/composition-api';
import { useImage } from '@wemade-vsf/composables';
import ProductPrice from 'components/catalog/Product/ProductPrice.vue';
import { statuses } from '~/helpers/productStockStatus';
import type { SearchResultItem } from '@wemade-vsf/search-loop54';

export default defineComponent({
  name: 'SearchProductCard',
  props: {
    product: {
      type: Object as PropType<SearchResultItem>,
      required: true
    },
    showNetPrices: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProductPrice,
    ProductLabels: () => import('components/catalog/Product/ProductLabels.vue')
  },
  setup (props) {
    const { app, $fc } = useContext();

    const { imageSizes: { productCard: imageSize }, getMagentoImage } = useImage();

    const attributes = computed(() => {
      return props.product.attributes.reduce((acc, attr) => {
        acc[attr.name] = attr.values
        return acc
      }, {})
    });

    function getProductImage(url: string) {
      return getMagentoImage('/media/catalog/product' + url)
    }

    const result = computed(() => {
      const image = attributes.value['small_image']
        ? getProductImage(attributes.value['small_image'][0])
        : '/placeholder.jpg';
      let link = attributes.value['url']?.[0]
        ? new URL(attributes.value['url'][0]).pathname
        : '';

      const priceSuffix = props.showNetPrices ? '_ex_vat' : '';
      const regularPrice = attributes.value[`regular_price${priceSuffix}`]?.[0]
        ? Number(attributes.value[`regular_price${priceSuffix}`][0])
        : null;
      let specialPrice = attributes.value[`price${priceSuffix}`]?.[0]
        ? Number(attributes.value[`price${priceSuffix}`][0])
        : null;

      if (regularPrice !== null && specialPrice !== null && specialPrice >= regularPrice) {
        specialPrice = null      
      }

      const productLabels = attributes.value['labels'] || [];

      const hasLabels = productLabels.length > 0;
      const hidePriceAtt = attributes.value['hide_price']?.[0] ?? '0';

      const labelProduct = {
        product_labels: productLabels.map(label => JSON.parse(label))
      }

      return {
        image,
        sku: props.product.id,
        link,
        name: attributes.value['name']?.[0] || '',
        manufacturer: attributes.value['manufacturer']?.[0] || '',
        type: attributes.value['type']?.[0] || '',
        hasLabels,
        labelProduct,
        hidePrice: hidePriceAtt === '1',
        price: {
          regular: regularPrice ? $fc(regularPrice) : null,
          special: specialPrice ? $fc(specialPrice) : null
        }
      }
    })

    const stockStatus = computed(() => {
      if (attributes.value['custom_stock_status']?.[0]) {
        return statuses[attributes.value['custom_stock_status'][0]]
      }
      const productStockStatus = attributes.value['quantity_and_stock_status']?.[0];
      return productStockStatus === 'In Stock' ? statuses['1'] : statuses['6'];
    });

    function sendEvent() {
      //@ts-ignore
      app.$loop54.createEvent('click', { type: 'Product', id: props.product.id })
    }

    return {
      imageSize,
      result,
      stockStatus,
      sendEvent
    }
  }
})
