import type { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';
import { useWmAttributes } from '@wemade-vsf/catalog/lib/runtime/product/composables/useWmAttributes';

export const statuses = {
  '1': {
    label: 'stockStatus.inStock',
    indicator: 'var(--_c-green-primary)'
  },
  '2': {
    label: 'stockStatus.unconfirmedDelivery',
    indicator: 'var(--_c-red-primary)'
  },
  '3': {
    label: 'stockStatus.inRemote',
    indicator: 'var(--_c-yellow-primary)'
  },
  '4': {
    label: 'stockStatus.inBackorder',
    indicator: 'var(--_c-yellow-primary)'
  },
  '5': {
    label: 'stockStatus.specialOrder',
    indicator: 'var(--_c-yellow-primary)'
  },
  '6': {
    label: 'stockStatus.notInStock',
    indicator: 'var(--_c-red-primary)'
  },
  '7': {
    label: 'stockStatus.fewInStock',
    indicator: 'var(--_c-green-primary)'
  }
}

export function getProductStockStatus(product: Product) {
  if (!product) return statuses['1'];
  const { productAttributes } = useWmAttributes();
  if (productAttributes.value?.custom_stock_status?.value) {
    return statuses[productAttributes.value.custom_stock_status.value];
  }
  const att = product.wm_custom_attributes?.find(a => a.attribute_code === 'custom_stock_status');
  if (att && statuses[att.value]) {
    return statuses[att.value];
  }

  return product.stock_status === 'IN_STOCK' ? statuses['1'] : statuses['6'];
}