















import {
  defineComponent,
  PropType,
  ref,
} from '@nuxtjs/composition-api'
import type { RangeFacet } from '@wemade-vsf/search-loop54'
import RangeFilter from 'components/search-loop54/SearchResult/Renderer/Range.vue'

export default defineComponent({
  name: 'FilterItem',
  components: {
    RangeFilter,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    facet: {
      type: Object as PropType<RangeFacet>,
      required: true,
    },
  },
  emits: ['open', 'changeFilter'],
  setup (props, { emit }) {

    const panelStyle = ref({
      left: '0px',
    })
    const filterRoot = ref<HTMLElement>()

    function calculatePanelPosition() {
      if (!filterRoot.value) {
        return
      }
      const rect = filterRoot.value.getBoundingClientRect()
      const windowWidth = window.innerWidth
      if ((rect.left + 300) > (windowWidth - 16)) {
        panelStyle.value.left = `${(windowWidth - 300 - 16) - rect.left}px`
      }
    }

    const toggle = () => {
      if (!props.open) {
        calculatePanelPosition()
      }
      emit('open', !props.open)
    }

    function selectFilter(data) {
      emit('changeFilter', data)
    }

    return {
      filterRoot,
      panelStyle,
      selectFilter,
      toggle,
    }
  }
})
