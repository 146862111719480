

























































import {
  defineComponent,
  computed,
  ref,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api'
import type { EntitiesResponse } from '@wemade-vsf/search-loop54';
import { useFilteredProductsGrid } from '../composables/useFilteredProductsGrid'
import { SfHeading, SfLink, SfBadge } from '@storefront-ui/vue'
import ProductsGrid from 'components/search-loop54/Category/ProductsGrid.vue'
import FilterItem from './FilterItem.vue'
import type { RangeFacet } from '@wemade-vsf/search-loop54'

export default defineComponent({
  name: 'FilteredProductsGrid',
  components: {
    SfHeading,
    SfLink,
    SfBadge,
    FilterItem,
    ProductsGrid,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    linktext: {
      type: String,
      default: ''
    },
    categoryUid: {
      type: String,
      default: ''
    },
    availableFilters: {
      type: Array,
      default: () => []
    },
    pageSize: {
      type: [Number, String],
      default: 12
    },
    columns: {
      type: [Number, String],
      default: 4
    }
  },
  setup (props) {
    const { app, localePath } = useContext()

    const hasLink = computed(() => !!props.link && !!props.linktext)
    const isExternal = computed(() => hasLink && props.link.startsWith('http'))
    const ctaLink = computed(() => isExternal ? props.link : localePath(props.link))

    const currentPage = ref(1)
    const pageSize = ref(Number(props.pageSize))

    const products = ref<EntitiesResponse['results']['items']>([]);
    const facets = ref<RangeFacet[]>([]);
    const pagination = ref<EntitiesResponse['pagination']>({});

    const selectedFacets = ref<Record<string, { min: number, max: number }>>({});

    const {
      loading,
      filterLabels,
      loadProducts,
    } = useFilteredProductsGrid(props.availableFilters as string[])

    async function getEntities(reloadFacets: boolean = true) {
      const response = await loadProducts({
        page: currentPage.value,
        itemsPerPage: pageSize.value,
        filter: {
          attributeName: 'category_uids',
          attributeValue: props.categoryUid
        },
        facets: selectedFacets.value,
      })
      products.value = response?.results?.items ?? []
      if (reloadFacets) {
        facets.value = (response?.results?.facets as RangeFacet[]) ?? []
      }
      pagination.value = response?.pagination ?? null
    }

    const getFilterLabel = (facetName: string): string => {
      return filterLabels.value?.[facetName] || facetName
    }

    const openFilter = ref('')
    const openOrCloseFilter = (facet: RangeFacet, newOpen: boolean) => {
      if (newOpen) {
        openFilter.value = facet.name
      } else {
        openFilter.value = ''
      }
    }

    async function changeFilter(facetName: string, data: { min: number, max: number }) {
      selectedFacets.value[facetName] = data
      await getEntities(false)
    }

    async function removeSelectedFacet(facetName: string) {
      openFilter.value = ''
      delete selectedFacets.value[facetName]
      await getEntities()
    }

    onMounted(async () => {
      if (props.categoryUid) {
        await getEntities()
      }
    })

    return {
      hasLink,
      isExternal,
      ctaLink,
      loading,
      products,
      facets,
      pagination,
      openFilter,
      selectedFacets,
      changeFilter,
      openOrCloseFilter,
      getFilterLabel,
      removeSelectedFacet,
    }
  }
})
