










































import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
  watch,
} from '@nuxtjs/composition-api';
import type { RangeFacet } from '@wemade-vsf/search-loop54';
import FilterBox from 'components/catalog/Category/Filters/Renderer/FilterBox.vue';

export default defineComponent({
  name: 'SearchFilterRange',
  props: {
    title: {
      type: String,
      default: ''
    },
    facet: {
      type: Object as PropType<RangeFacet>,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FilterBox
  },
  emits: ['selectFilter'],
  setup (props, { emit }) {
    const slider = ref<HTMLElement | null>(null);
    const timeout = ref<NodeJS.Timeout | number | null>(null);
    const minAngle = ref<number>(Math.max(Number(props.facet.selectedMin ?? props.facet.min), Number(props.facet.min)));
    const maxAngle = ref<number>(Math.min(Number(props.facet.selectedMax ?? props.facet.max), Number(props.facet.max)));

    function getPercent(value) {
      return ((value - Number(props.facet.min)) / (Number(props.facet.max) - Number(props.facet.min))) * 100;
    }

    const updateCssVars = (minVal, maxVal) => {
      const leftPercent = getPercent(minVal);
      const rightPercent = getPercent(maxVal);
      slider.value?.style.setProperty("--highlightWidth", `${Math.abs(leftPercent - rightPercent)}%`);
      slider.value?.style.setProperty("--leftPercent", `${leftPercent}%`);
    }

    const updateValues = (minVal, maxVal) => {
      updateCssVars(minVal, maxVal);
      if (timeout.value) {
        // @ts-ignore
        clearTimeout(timeout.value);
      }
      timeout.value = setTimeout(() => {
        emit('selectFilter', { min: minVal, max: maxVal })
      }, 1500);
    }

    const minValue = computed({
      get: () => minAngle.value,
      set: (val) => {
        if (val > maxAngle.value) {
          maxAngle.value = val
        }
        minAngle.value = val
        updateValues(minAngle.value, maxAngle.value)
      }
    })

    const maxValue = computed({
      get: () => maxAngle.value,
      set: (val) => {
        if (val < minAngle.value) {
          minAngle.value = val
        }
        maxAngle.value = val;
        updateValues(minAngle.value, maxAngle.value)
      }
    })

    watch(() => props.facet, () => {
      minAngle.value = Math.max(Number(props.facet.selectedMin ?? props.facet.min), Number(props.facet.min));
      maxAngle.value = Math.min(Number(props.facet.selectedMax ?? props.facet.max), Number(props.facet.max));
      updateCssVars(minAngle.value, maxAngle.value)
    }, { immediate: true, deep: true })

    onMounted(() => {
      updateCssVars(minAngle.value, maxAngle.value);
    })

    return {
      slider,
      minValue,
      maxValue
    }
  }
})
