import { useContext, computed, ref, readonly } from '@nuxtjs/composition-api'
import type { Ref } from '@nuxtjs/composition-api'
import { useSearchFacetsStore } from '@wemade-vsf/search-loop54'
import { storeToRefs } from 'pinia'
import type {
  EntitiesResponse,
  FilterParam,
  FilterParams,
  Loop54Config,
  SearchResultItem,
} from '@wemade-vsf/search-loop54'
import type { SortingModel } from '@wemade-vsf/catalog/lib/runtime/category/composables/useFacet/sortingOptions';
import { Logger } from '@wemade-vsf/core'

export type SearchParams = {
  itemsPerPage?: number
  sort?: string
  page?: number
  filter: { attributeName: string, attributeValue: string }
  facets?: FilterParams
}

type UseFilteredProductsGrid = {
  loading: Readonly<Ref<boolean>>
  filterLabels: Ref<Record<string, string>>
  loadProducts: (params: SearchParams) => Promise<EntitiesResponse | null>
}

export function useFilteredProductsGrid(availableFilters: string[]): UseFilteredProductsGrid {
  const { app, $config } = useContext()
  const loop54Config: Loop54Config = $config.loop54
  const rangeFacets = loop54Config.rangeFacets
  const cardAttributes = loop54Config.cardAttributes;

  const searchFacetsStore = useSearchFacetsStore()
  const sortOptions = searchFacetsStore.sortOptions
  const sortingOptions = Object.keys(sortOptions).map((s) => ({
    value: s,
    label: `loop54sort.${s}`
  })) as SortingModel['options'];
  const { filterLabels, filterAttributes } = storeToRefs(searchFacetsStore)

  const loading = ref(true)

  const availableFacets = computed<FilterParam[]>(() => availableFilters.reduce((acc, filter) => {
    if (rangeFacets.includes(filter)) {
      acc.push({
        type: 'range',
        name: filter,
        attributeName: filter,
      })
    } else {
      acc.push({
        type: 'distinct',
        name: filter,
        attributeName: filter,
      })
    }
    return acc
  }, []))

  async function loadProducts(params: SearchParams): Promise<EntitiesResponse | null> {
    Logger.debug('useFilteredProductsGrid/loadProducts', params)
    loading.value = true
    let result = null

    const client =  app.$loop54

    try {
      if (!filterAttributes.value.length) {
        await searchFacetsStore.loadAttributes()
      }
      const page = params.page || 1
      const itemsPerPage = params.itemsPerPage || 12
      const sortByKey = params.sort || 'relevance'
      const sortBy = sortOptions[sortByKey] && sortByKey !== 'relevance'
        ? [sortOptions[sortByKey], sortOptions['relevance']]
        : [sortOptions.relevance]
      const { attributeName, attributeValue } = params.filter

      const options = {
        skip: (page - 1) * itemsPerPage,
        take: itemsPerPage,
        facets: availableFacets.value.map((facet) => ({
          ...facet,
          ...params.facets?.[facet.name] ? { selected: params.facets[facet.name] } : {},
        })),
        sortBy,
      }
      result = new Promise((resolve, reject) => {
        client.getEntitiesByAttribute(attributeName, attributeValue, options)
          .then((response: { data?: EntitiesResponse | null }) => {
            if (!response.data) {
              resolve(null)
              return
            }
            const data = response.data
            const mappedResults = {
              results: {
                count: data.results.count,
                facets: data.results.facets,
                items: data.results.items.map((item: SearchResultItem) => ({
                  type: item.type,
                  id: item.id,
                  attributes: item.attributes.filter((attr) => cardAttributes.includes(attr.name))
                })),
              },
              pagination: {
                currentPage: page,
                totalPages: Math.ceil(data.results.count / itemsPerPage),
                totalItems: data.results.count,
                itemsPerPage: itemsPerPage,
              },
              sort: {
                selected: sortByKey,
                options: sortingOptions,
              }
            }
            resolve(mappedResults)
          })
          .catch((error) => {
            reject(error)
          })
      })
    } catch (error) {
      Logger.error('useFilteredProductsGrid/loadProducts', error)
    } finally {
      loading.value = false
    }

    return result
  }

  return {
    filterLabels,
    loading: readonly(loading),
    loadProducts,
  }
}